.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}



.control-label {
  font-weight: bolder !important;
  font-size: larger
  
}

.form-control {
  height: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color : #d1d1d1; 
}

.loadingSpinner {
  height: 5em;
  width: 5em;
  position: fixed;
  top:50%;
  left:50%;
  z-index: 99
}

#logo {
  height: 30%;
  width: 40%;
  margin-top: -10%;
  margin-bottom: -15%;
  margin-left: -6%
}

.ratecard a {
  text-decoration: none
}

[class*="col-"]:not(:first-child,:last-child) {
  padding-right:17px;
  padding-left:17px;
}